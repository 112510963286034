import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    data:{}
}

export const initSlice = createSlice({
    name: 'init/init',
    initialState,
    reducers: {
        setInit: (state, action) => {
            state.data = {...state.data, ...action.payload}
        },
        setProgram: (state, action) => {
            state.data.program = action.payload
        },
        setBranch: (state, action) => {
            state.data.branch = action.payload
        },
        updateBranch: (state, action) => {
            state.data.branch = state.data.branch.map((b)=>{
                return b;
            })
        },
    },
})

export const { setInit, setProgram, setBranch, updateBranch } = initSlice.actions

export default initSlice.reducer
