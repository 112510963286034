const appConfig = {
    filePrefix: 'https://theappxperts.com/chahal/',
    filePrefix2: 'https://maplelabsystems.com/ch/',
    apiPrefix: 'https://theappxperts.com/chahal/api/index.php',
    apiPrefix2: 'https://maplelabsystems.com/ch/api/index.php',
    authenticatedEntryPath: '/lead',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
