import { jsonToQueryString } from 'libs/functions'
import ApiService from './ApiService'

export async function apiGetLead(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'getlead', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiGetStudent(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'getstudents', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageSetStudent(data) {
    try {
        const resp = await ApiService.fetchData({
            url: '',
            method: 'post',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageGetProgram(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'getprogram', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageSetProgram(data) {
    try {
        const resp = await ApiService.fetchData({
            url: '',
            method: 'post',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageGetBranch(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'getbranch', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageGetVendor(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'getvendor', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageGetBooks(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'getbooks', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageGetInit(data, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action: 'init', ...query })}`,
            method: 'get',
            data,
        })
        if (resp.data) {
            
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageSetBookAssigned(data) {
    try {
        const resp = await ApiService.fetchData({
            url: '',
            method: 'post',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiManageSetBookReceived(data) {
    try {
        const resp = await ApiService.fetchData({
            url: '',
            method: 'post',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiPostData(data) {
    try {
        const resp = await ApiService.fetchData({
            url: '',
            method: 'post',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiGetData(action, query) {
    try {
        const resp = await ApiService.fetchData({
            url: `?${jsonToQueryString({ action, ...query })}`,
            method: 'get',
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}

export async function apiDeleteData(data) {
    try {
        const resp = await ApiService.fetchData({
            url: '',
            method: 'post',
            data,
        })
        if (resp.data) {
            return resp.data
        }
    } catch (errors) {
        return {
            status: 'failed',
            message: errors?.response?.data?.message || errors.toString(),
        }
    }
}