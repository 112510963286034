export const phoneRegExp =
    /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4}$/im

export const optionsReference = [
    {
        value: 'newspaper',
        label: 'Newspaper',
    },
    {
        value: 'internet',
        label: 'Internet',
    },
    {
        value: 'friends',
        label: 'Friends',
    },
    {
        value: 'facebook',
        label: 'Facebook',
    },
    {
        value: 'other',
        label: 'Other',
    },
]

export const optionsMaterial = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: '1',
        label: 'With Material',
    },
    {
        value: '0',
        label: 'Without Material',
    },
]

export const optionsPaidStatus= [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'paid',
        label: 'Paid',
    },
    {
        value: 'unpaid',
        label: 'Unpaid',
    },
]

export const optionsGSTType = [
    {
        value: 'regular',
        label: 'Regular',
    },
    {
        value: 'composition',
        label: 'Composition',
    },
    {
        value: 'unregistered',
        label: 'Unregistered',
    },
]

export const stateList = [
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu',
    'Jharkhand',
    'Karnataka',
    'Kashmir',
    'Kerala',
    'Ladakh',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Other',
]
