import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    loading:false
}

export const drawerSlice = createSlice({
    name: 'common/footer',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading= action.payload
        },
    },
})

export const { setLoading } = drawerSlice.actions

export default drawerSlice.reducer
