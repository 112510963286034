import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';
export const initialState = {
    drawersData: {

    },
}

export const drawerSlice = createSlice({
    name: 'common/drawer',
    initialState,
    reducers: {
        openDrawer: (state, action) => {
            state.drawersData[uuidv4()] = action.payload
        },
        closeDrawer: (state, action) => {
            state.drawersData[action.payload].isOpen = false
        },
        onClosedDrawer: (state, action) => {
            delete state.drawersData[action.payload];
        },
    },
})

export const { openDrawer, closeDrawer, onClosedDrawer } = drawerSlice.actions

export default drawerSlice.reducer
