import dayjs from 'dayjs'
import { stateList } from './static-data'

export const jsonToQueryString = (jsonData) => {
    const u = new URLSearchParams(jsonData).toString()
    return u
}

export const displayDate = (date, withtime) => {
    let format = 'DD/MM/YYYY'
    if (withtime) {
        format = 'DD/MM/YYYY hh:mm A'
    }
    return dayjs(date).format(format)
}

export const passingDate = (date, withtime) => {
    let format = 'YYYY-MM-DD'
    if (withtime) {
        format = 'YYYY-MM-DD HH:mm:ss'
    }

    return dayjs(date).format(format)
}

export const getUnixTime = () => {
    return dayjs(new Date()).valueOf()
}

export const currencyFormat = (value) => {
    try {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }).format(value)
    } catch {
        return value
    }
}

export const maxDate = dayjs(new Date()).toDate()

export const getStateData = () => {
    return stateList.map((state) => {
        return {
            label: state,
            value: state,
        }
    })
}
