import { combineReducers } from '@reduxjs/toolkit'
import drawer from './drawerSlice'
import footer from './footerSlice'

const reducer = combineReducers({
    drawer,
    footer
})

export default reducer
